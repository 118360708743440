
const Terms = () => {
  return (
    <div className="container py-12 sm:py-24">
      <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
      <div className="prose prose-slate max-w-none">
        <p className="lead">Last updated: {new Date().toLocaleDateString()}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Agreement to Terms</h2>
        <p>
          These Terms of Service constitute a legally binding agreement made between you and Imfiy BV concerning your access to and use of our website and services. You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Intellectual Property Rights</h2>
        <p>
          Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. User Representations</h2>
        <p>By using the Site, you represent and warrant that:</p>
        <ul className="list-disc pl-6 mt-4 space-y-2">
          <li>All registration information you submit will be true, accurate, current, and complete</li>
          <li>You will maintain the accuracy of such information and promptly update such registration information as necessary</li>
          <li>You have the legal capacity and you agree to comply with these Terms of Service</li>
          <li>You are not a minor in the jurisdiction in which you reside</li>
          <li>You will not access the Site through automated or non-human means</li>
          <li>You will not use the Site for any illegal or unauthorized purpose</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Services</h2>
        <p>
          We provide AI-powered solutions and expert guidance to help businesses transform their digital presence. Our services include but are not limited to:
        </p>
        <ul className="list-disc pl-6 mt-4 space-y-2">
          <li>AI-driven content generation and optimization</li>
          <li>Professional development guidance</li>
          <li>Business automation solutions</li>
          <li>Digital transformation consulting</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Modifications to Terms</h2>
        <p>
          We reserve the right to make changes or modifications to these Terms of Service at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Terms of Service, and you waive any right to receive specific notice of each such change.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Contact Information</h2>
        <p>
          Questions about the Terms of Service should be sent to us via our contact page or through the provided communication channels.
        </p>
      </div>
    </div>
  )
}

export default Terms
