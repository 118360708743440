
import { NavigationMenu, NavigationMenuList, NavigationMenuItem } from "@/components/ui/navigation-menu"
import { Link } from "react-router-dom"
import { Menu } from "lucide-react"
import { useState } from "react"

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-16 items-center justify-between">
        <Link to="/" className="flex items-center">
          <img 
            src="/lovable-uploads/a26c4e30-88ef-4753-859c-691610d07765.png" 
            alt="Imfiy BV Company Logo" 
            className="h-8"
          />
        </Link>
        
        {/* Mobile menu button */}
        <button 
          className="md:hidden flex items-center justify-center p-2 rounded-md text-imfiy-primary"
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
        >
          <Menu className="h-6 w-6" aria-hidden="true" />
        </button>
        
        {/* Desktop navigation */}
        <div className="hidden md:block">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationMenuItem>
                <Link to="/" className="group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-imfiy-secondary/10 hover:text-imfiy-primary focus:bg-imfiy-secondary/10 focus:text-imfiy-primary focus:outline-none disabled:pointer-events-none disabled:opacity-50">
                  Home
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link to="/about" className="group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-imfiy-secondary/10 hover:text-imfiy-primary focus:bg-imfiy-secondary/10 focus:text-imfiy-primary focus:outline-none disabled:pointer-events-none disabled:opacity-50">
                  About
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link to="/platforms" className="group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-imfiy-secondary/10 hover:text-imfiy-primary focus:bg-imfiy-secondary/10 focus:text-imfiy-primary focus:outline-none disabled:pointer-events-none disabled:opacity-50">
                  Platforms
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        
        {/* Mobile navigation */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-background border-b shadow-lg z-50 md:hidden">
            <nav className="flex flex-col">
              <Link 
                to="/" 
                className="px-4 py-3 text-sm font-medium hover:bg-imfiy-secondary/10 hover:text-imfiy-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
              <Link 
                to="/about" 
                className="px-4 py-3 text-sm font-medium hover:bg-imfiy-secondary/10 hover:text-imfiy-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </Link>
              <Link 
                to="/platforms" 
                className="px-4 py-3 text-sm font-medium hover:bg-imfiy-secondary/10 hover:text-imfiy-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                Platforms
              </Link>
            </nav>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
