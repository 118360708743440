
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { ArrowRight, Check, ArrowUpRight } from "lucide-react"
import { Link } from "react-router-dom"

const Index = () => {
  return (
    <div className="flex flex-col">
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-background px-4 sm:px-6 py-20 sm:py-32 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-imfiy-gradient via-background to-background" />
        <div className="container relative z-10">
          <div className="mx-auto max-w-2xl text-center animate-fade-in">
            <span className="inline-flex items-center gap-2 rounded-full bg-muted/60 px-4 py-1.5 text-sm font-medium text-muted-foreground mb-6">
              <span className="relative flex h-2 w-2">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-imfiy-primary opacity-75"></span>
                <span className="relative inline-flex h-2 w-2 rounded-full bg-imfiy-primary"></span>
              </span>
              Transforming customers with AI
            </span>
            <h1 className="font-mundial text-[40px] sm:text-[56px] leading-[1.1] tracking-tight text-imfiy-primary mb-6">
              Digital Solutions for <span className="text-imfiy-secondary">Modern Life</span>
            </h1>
            <p className="text-lg sm:text-xl leading-relaxed text-muted-foreground px-2 sm:px-0 mb-10">
              Empowering customers with AI-powered solutions and expert guidance. Transform your digital presence with Imfiy BV.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6">
              <Button asChild size="lg" variant="gradient" className="group text-button w-full sm:w-auto relative overflow-hidden">
                <Link to="/platforms" className="relative z-10">
                  Explore Our Platforms
                  <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" aria-hidden="true" />
                </Link>
              </Button>
              <Button variant="outline" size="lg" asChild className="text-button w-full sm:w-auto group">
                <a href="mailto:info@imfiy.com">
                  Contact Us
                  <ArrowUpRight className="ml-2 h-5 w-5 transition-transform group-hover:-translate-y-1 group-hover:translate-x-1" aria-hidden="true" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Overview Section */}
      <section className="bg-muted/30 py-20 sm:py-32 px-4 sm:px-6 relative overflow-hidden">
        <div className="container relative z-10">
          <div className="grid gap-12 lg:gap-16 md:grid-cols-2">
            <div className="space-y-6 animate-fade-in [--animate-delay:200ms]">
              <div className="inline-flex items-center gap-2 rounded-lg bg-imfiy-primary/10 px-3 py-1 text-sm font-medium text-imfiy-primary">
                Expert Guidance
              </div>
              <h2 className="text-3xl sm:text-4xl font-mundial text-imfiy-primary leading-tight">Personalized Guidance & Expert Support</h2>
              <p className="text-lg text-muted-foreground">
                Get expert advice and support for personal and professional growth. Our platform connects you with experienced advisors who can help guide you through important decisions.
              </p>
              <ul className="space-y-4 mt-6">
                {["Professional development guidance", "Relationship and well-being advice", "Decision-making support"].map((item, index) => (
                  <li key={index} className="flex items-start gap-3 group">
                    <div className="rounded-full p-1 bg-imfiy-primary/10 text-imfiy-primary group-hover:bg-imfiy-primary group-hover:text-white transition-colors">
                      <Check className="h-4 w-4" aria-hidden="true" />
                    </div>
                    <span className="text-foreground">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="space-y-6 animate-fade-in [--animate-delay:400ms]">
              <div className="inline-flex items-center gap-2 rounded-lg bg-imfiy-secondary/10 px-3 py-1 text-sm font-medium text-imfiy-secondary">
                AI Solutions
              </div>
              <h2 className="text-3xl sm:text-4xl font-mundial text-imfiy-primary leading-tight">AI-Powered Personal Solutions</h2>
              <p className="text-lg text-muted-foreground">
                Leverage the power of AI to automate and optimize your daily life. From social media management to content creation, our tools help you stay ahead.
              </p>
              <ul className="space-y-4 mt-6">
                {["AI-driven content generation", "Automated social media scheduling", "Analytics and performance tracking"].map((item, index) => (
                  <li key={index} className="flex items-start gap-3 group">
                    <div className="rounded-full p-1 bg-imfiy-secondary/10 text-imfiy-secondary group-hover:bg-imfiy-secondary group-hover:text-white transition-colors">
                      <Check className="h-4 w-4" aria-hidden="true" />
                    </div>
                    <span className="text-foreground">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 sm:py-32 px-4 sm:px-6">
        <div className="container">
          <div className="text-center mb-12 sm:mb-16 max-w-2xl mx-auto">
            <h2 className="text-3xl sm:text-4xl font-mundial text-imfiy-primary mb-4">Trusted by Customers Worldwide</h2>
            <p className="text-lg text-muted-foreground">Our platforms have helped thousands of individuals achieve their goals</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {[
              { number: "3.7M", label: "Total Sessions by Advisors" },
              { number: "50K+", label: "Clients" },
              { number: "99.8K", label: "Total Reviews" }
            ].map((stat, index) => (
              <Card key={index} className="text-center p-6 sm:p-8 hover:shadow-lg transition-all hover:-translate-y-1 bg-gradient-to-b from-background to-muted/20">
                <CardContent className="p-0">
                  <div className="text-3xl sm:text-4xl font-bold text-imfiy-primary mb-2">{stat.number}</div>
                  <p className="text-base sm:text-lg text-muted-foreground">{stat.label}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-muted/30 py-20 sm:py-32 px-4 sm:px-6 relative overflow-hidden">
        <div className="container relative z-10">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl sm:text-4xl font-mundial text-imfiy-primary mb-6">Ready to Transform Your Life?</h2>
            <p className="text-lg sm:text-xl text-muted-foreground mb-10">
              Join thousands of customers already benefiting from our AI-powered solutions and expert guidance.
            </p>
            <Button asChild size="lg" variant="gradient" className="text-button w-full sm:w-auto group">
              <a href="mailto:info@imfiy.com" className="inline-flex items-center">
                Get Started Today
                <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" aria-hidden="true" />
              </a>
            </Button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Index
