
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Check } from "lucide-react"
import { Link } from "react-router-dom"

const Platforms = () => {
  return (
    <div className="flex flex-col">
      {/* Hero Section */}
      <section className="relative bg-background px-6 py-24 sm:py-32 lg:px-8">
        <div className="container">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">Our Platforms</h1>
            <p className="mt-6 text-lg leading-8 text-muted-foreground">
              Discover our suite of AI-powered solutions designed to transform your daily operations
            </p>
          </div>
        </div>
      </section>

      {/* Platforms Grid */}
      <section className="py-24">
        <div className="container">
          <div className="grid gap-8 md:grid-cols-3">
            <Card>
              <CardHeader>
                <CardTitle>AI Personal Assistant</CardTitle>
                <CardDescription>Streamline your life with AI</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Automated task management</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Smart scheduling</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Data analysis</span>
                  </li>
                </ul>
              </CardContent>
              <CardFooter>
                <Button asChild variant="gradient" className="w-full">
                  <a href="mailto:info@imfiy.com">Learn More</a>
                </Button>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Content Creator Pro</CardTitle>
                <CardDescription>AI-powered content generation</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Social media posts</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Blog articles</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Marketing copy</span>
                  </li>
                </ul>
              </CardContent>
              <CardFooter>
                <Button asChild variant="secondary" className="w-full">
                  <a href="mailto:info@imfiy.com">Learn More</a>
                </Button>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Analytics Suite</CardTitle>
                <CardDescription>Deep personal insights</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Real-time monitoring</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Predictive analytics</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <Check className="h-4 w-4 text-imfiy-primary" />
                    <span>Custom reporting</span>
                  </li>
                </ul>
              </CardContent>
              <CardFooter>
                <Button asChild variant="default" className="w-full">
                  <a href="mailto:info@imfiy.com">Learn More</a>
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Platforms
