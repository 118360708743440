
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ArrowRight, Users, Lightbulb, Star } from "lucide-react";
import { Link } from "react-router-dom";

const About = () => {
  return <div className="flex flex-col">
      {/* Hero Section */}
      <section className="relative bg-background px-6 py-24 sm:py-32 lg:px-8 overflow-hidden">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-imfiy-gradient/30 via-background to-background" />
        <div className="container">
          <div className="mx-auto max-w-3xl text-center">
            <span className="inline-flex items-center gap-2 rounded-full bg-muted/60 px-4 py-1.5 text-sm font-medium text-muted-foreground mb-6">
              <span className="relative flex h-2 w-2">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-imfiy-primary opacity-75"></span>
                <span className="relative inline-flex h-2 w-2 rounded-full bg-imfiy-primary"></span>
              </span>
              Discover our story
            </span>
            <h1 className="text-4xl font-bold tracking-tight sm:text-6xl text-imfiy-primary">About Imfiy BV</h1>
            <p className="mt-6 text-lg leading-8 text-muted-foreground">
              We are a forward-thinking technology company dedicated to empowering customers through innovative AI solutions and expert guidance.
            </p>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-muted/50 py-24">
        <div className="container">
          <div className="mx-auto max-w-3xl">
            <h2 className="text-3xl font-bold mb-6 text-center text-imfiy-primary">Our Mission</h2>
            <p className="text-lg text-muted-foreground text-center mb-12">
              To revolutionize daily operations through cutting-edge AI technology and personalized professional guidance.
            </p>
            <div className="grid gap-8 md:grid-cols-3">
              <Card className="bg-gradient-to-b from-background to-muted/20 border-none shadow-md hover:shadow-lg transition-all hover:-translate-y-1">
                <CardContent className="space-y-4 text-center p-6">
                  <div className="bg-imfiy-primary/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto">
                    <Lightbulb className="h-6 w-6 text-imfiy-primary" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-semibold text-imfiy-primary">Innovation</h3>
                  <p className="text-muted-foreground">Pushing boundaries with advanced AI solutions</p>
                </CardContent>
              </Card>
              
              <Card className="bg-gradient-to-b from-background to-muted/20 border-none shadow-md hover:shadow-lg transition-all hover:-translate-y-1">
                <CardContent className="space-y-4 text-center p-6">
                  <div className="bg-imfiy-secondary/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto">
                    <Star className="h-6 w-6 text-imfiy-secondary" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-semibold text-imfiy-primary">Excellence</h3>
                  <p className="text-muted-foreground">Delivering exceptional results for our clients</p>
                </CardContent>
              </Card>
              
              <Card className="bg-gradient-to-b from-background to-muted/20 border-none shadow-md hover:shadow-lg transition-all hover:-translate-y-1">
                <CardContent className="space-y-4 text-center p-6">
                  <div className="bg-imfiy-primary/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto">
                    <Users className="h-6 w-6 text-imfiy-primary" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-semibold text-imfiy-primary">Integrity</h3>
                  <p className="text-muted-foreground">Building trust through transparent practices</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-muted/30 py-20 sm:py-24 relative overflow-hidden">
        <div className="container relative z-10">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold text-imfiy-primary mb-6">Ready to Experience Our Solutions?</h2>
            <p className="text-lg text-muted-foreground mb-10">
              Join thousands of satisfied customers who have transformed their lives with our AI-powered platforms and expert guidance.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6">
              <Button asChild size="lg" variant="gradient" className="text-button w-full sm:w-auto group">
                <Link to="/platforms" className="inline-flex items-center">
                  Explore Our Platforms
                  <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" aria-hidden="true" />
                </Link>
              </Button>
              <Button asChild variant="outline" size="lg" className="w-full sm:w-auto">
                <a href="mailto:info@imfiy.com">Email Us</a>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>;
};
export default About;
