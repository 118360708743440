
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Mail } from "lucide-react";

const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );
    
    // Send to analytics or error tracking service if available
  }, [location.pathname]);

  return (
    <div className="min-h-[70vh] flex flex-col items-center justify-center px-4 py-16">
      <div className="text-center max-w-md">
        <h1 className="text-5xl font-bold text-imfiy-primary mb-6">404</h1>
        <p className="text-xl text-muted-foreground mb-8">
          We can't seem to find the page you're looking for.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button asChild variant="outline" size="lg">
            <Link to="/" className="inline-flex items-center">
              <ArrowLeft className="mr-2 h-4 w-4" />
              Return Home
            </Link>
          </Button>
          <Button asChild variant="gradient" size="lg">
            <a href="mailto:info@imfiy.com" className="inline-flex items-center">
              <Mail className="mr-2 h-4 w-4" />
              Email Support
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
