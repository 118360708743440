
const Privacy = () => {
  return (
    <div className="container py-12 sm:py-24">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      <div className="prose prose-slate max-w-none">
        <p className="lead">Last updated: {new Date().toLocaleDateString()}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
        <p>
          Imfiy BV ("we" or "us" or "our") respects the privacy of our users ("user" or "you"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Collection of Your Information</h2>
        <p>
          We may collect information about you in a variety of ways. The information we may collect via the Site includes:
        </p>
        <ul className="list-disc pl-6 mt-4 space-y-2">
          <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email address, and telephone number, that you voluntarily give to us when you register or when you choose to participate in various activities related to the Site.</li>
          <li><strong>Usage Information:</strong> Information our servers automatically collect when you access the Site, such as your browser type, operating system, access times, and the pages you have viewed directly before and after accessing the Site.</li>
          <li><strong>Device Information:</strong> Information about your device and internet connection, including your IP address, device type, and browser type.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Use of Your Information</h2>
        <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>
        <ul className="list-disc pl-6 mt-4 space-y-2">
          <li>Create and manage your account</li>
          <li>Email you regarding your account or order</li>
          <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site</li>
          <li>Generate a personal profile about you to make future visits to the Site more personalized</li>
          <li>Increase the efficiency and operation of the Site</li>
          <li>Monitor and analyze usage and trends to improve your experience with the Site</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Disclosure of Your Information</h2>
        <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
        <ul className="list-disc pl-6 mt-4 space-y-2">
          <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
          <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Security of Your Information</h2>
        <p>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Contact Us</h2>
        <p>
          If you have questions or comments about this Privacy Policy, please contact us via our contact page or through the provided communication channels.
        </p>
      </div>
    </div>
  )
}

export default Privacy
