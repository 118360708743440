
import { Link } from "react-router-dom"
import { Linkedin, Mail, MapPin } from "lucide-react"
import { Separator } from "@/components/ui/separator"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="border-t bg-background">
      <div className="container py-12">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3 md:gap-8">
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-imfiy-primary">Imfiy BV</h3>
            <p className="text-sm text-muted-foreground max-w-xs">
              Empowering customers with AI solutions and expert guidance
            </p>
            <div className="text-sm text-muted-foreground space-y-3 pt-2">
              <div className="flex items-start gap-3">
                <MapPin className="h-5 w-5 text-imfiy-secondary mt-0.5" />
                <div>
                  <p>Raamplein 1</p>
                  <p>1016 XK Amsterdam</p>
                  <p>Netherlands</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Mail className="h-5 w-5 text-imfiy-secondary" />
                <a 
                  href="mailto:info@imfiy.com" 
                  className="hover:text-imfiy-primary transition-colors"
                >
                  info@imfiy.com
                </a>
              </div>
              <div className="flex items-center gap-3">
                <Linkedin className="h-5 w-5 text-imfiy-secondary" />
                <a 
                  href="https://www.linkedin.com/company/imfiy-bv/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:text-imfiy-primary transition-colors"
                >
                  LinkedIn
                </a>
              </div>
            </div>
            <div className="pt-3 text-sm text-muted-foreground">
              <p>VAT: NL856812419B01</p>
              <p>KVK: 67058094</p>
            </div>
          </div>
          
          <div className="space-y-6 md:col-span-2">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              <div className="space-y-4">
                <h4 className="text-sm font-medium text-imfiy-primary">Company</h4>
                <ul className="space-y-3 text-sm">
                  <li>
                    <Link 
                      to="/about" 
                      className="text-muted-foreground hover:text-imfiy-primary transition-colors flex items-center gap-2"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/platforms" 
                      className="text-muted-foreground hover:text-imfiy-primary transition-colors flex items-center gap-2"
                    >
                      Our Platforms
                    </Link>
                  </li>
                </ul>
              </div>
              
              <div className="space-y-4">
                <h4 className="text-sm font-medium text-imfiy-primary">Legal</h4>
                <ul className="space-y-3 text-sm">
                  <li>
                    <Link 
                      to="/privacy" 
                      className="text-muted-foreground hover:text-imfiy-primary transition-colors flex items-center gap-2"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/terms" 
                      className="text-muted-foreground hover:text-imfiy-primary transition-colors flex items-center gap-2"
                    >
                      Terms of Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        <Separator className="my-8 opacity-50" />
        
        <div className="text-center text-sm text-muted-foreground">
          © {currentYear} Imfiy BV. All rights reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer
